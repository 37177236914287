'use strict';

window.apiUri = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');

require('./theme');

// Load controllers
require('./controllers/DashboardCtrl');
require('./controllers/OverviewCtrl');
require('./controllers/LoginCtrl');
require('./controllers/ServersCtrl');
require('./controllers/NewsCtrl');
require('./controllers/PaymentsCtrl');
require('./controllers/ProfileCtrl');
require('./controllers/PaymentCtrl');
require('./controllers/AdsCtrl');
require('./controllers/FAQCtrl');
require('./controllers/DailyStatisticsCtrl');
require('./controllers/GameStatisticsCtrl');
require('./controllers/ServerStatisticsCtrl');
require('./controllers/CountryStatisticsCtrl');
require('./controllers/EarlyPaymentCtrl');
require('./controllers/RegistrationCtrl');
require('./controllers/ForgottenCtrl');
require('./controllers/SwitchUserCtrl');
require('./controllers/VerifyCtrl');
require('./controllers/RatesCtrl');
require('./controllers/HubCtrl');
require('./controllers/MCConfigCtrl');
require('./controllers/UnturnedConfigCtrl');
require('./controllers/AdminPaymentsCtrl');
require('./controllers/AdminNotificationsCtrl');
require('./controllers/AdminPendingUsers');
require('./controllers/ChatCtrl');
require('./controllers/FreshdeskCtrl');
require('./controllers/PrizeCtrl');
require('./controllers/VerifyIdCtrl');

// Declare app level module which depends on views, and components
angular.module('motdgd', [
    'ui.router',
    'ui.footable',
    'chart.js',
    'angularMoment',
    'pascalprecht.translate',
    'angular-content-editable',
    'datamaps',
    'ngCookies',
    'ngSanitize',
    'angularSpinner',
    'ui.bootstrap',
    'ui.bootstrap.datetimepicker',
    'colorpicker.module',
    'permission',
    'permission.ui',
    'vcRecaptcha',
    'leodido.caretAware',
    'ngFileSaver',
    'luegg.directives',
    //'ngDesktopNotification',

    // controllers
    'motdgd.dashboard',
    'motdgd.overview',
    'motdgd.login',
    'motdgd.servers',
    'motdgd.news',
    'motdgd.payments',
    'motdgd.profile',
    'motdgd.payment',
    'motdgd.ads',
    'motdgd.dailyStatistics',
    'motdgd.gameStatistics',
    'motdgd.serverStatistics',
    'motdgd.countryStatistics',
    'motdgd.earlyPayment',
    'motdgd.registration',
    'motdgd.forgotten',
    'motdgd.faq',
    'motdgd.switchUser',
    'motdgd.verify',
    'motdgd.rates',
    'motdgd.hub',
    'motdgd.mcconfig',
    'motdgd.unturnedconfig',
    'motdgd.adminPayments',
    'motdgd.adminNotifications',
    'motdgd.adminPendingUsers',
    'motdgd.chat',
    'motdgd.freshdesk',
    'motdgd.prizes',
    'motdgd.verifyId'
])
.constant('moment', window.momTimezone)
.constant('angularMomentConfig', {
    timezone: 'Europe/Paris'
})
.config(['$locationProvider', '$stateProvider', '$urlRouterProvider',
    function($locationProvider, $stateProvider, $urlRouterProvider) {
        $locationProvider.html5Mode(true)
        $urlRouterProvider.otherwise('/');
}])
.config(['$translateProvider', function ($translateProvider) {
    $translateProvider.
    useSanitizeValueStrategy('sanitize').
    useStaticFilesLoader({
        prefix: 'assets/locales/',
        suffix: '.json'
    }).
    preferredLanguage('en');
}])
.run(['PermPermissionStore', function(PermPermissionStore) {

    PermPermissionStore
    .defineManyPermissions(['extendedStats',
        'root', 'switchUser', 'modifyUser',
        'switchGlobal', 'supportChat', 'premium'],
        ['$rootScope', 'permissionName', 'transitionProperties',
        function ($rootScope, permissionName, transitionProperties) {
            try {
                return $rootScope.Permissions.indexOf(permissionName) != -1
                    || $rootScope.Permissions.indexOf('root') != -1;
            } catch(e) {
                return false;
            }
    }]);
}])
.run(['$state', '$rootScope', '$location', function($state, $rootScope, $location){

    if($location.path() != '/'
    && $location.path().indexOf('/registration') != 0
    && $location.path().indexOf('/verify') != 0) {
        $rootScope.previousPath = $location.path();
        $location.path('/');
    }
}]);

// Load services
require('./services/$api');
require('./services/AuthSrvc');
require('./services/UserSrvc');
require('./services/NewsSrvc');
require('./services/OverviewSrvc');
require('./services/PaymentSrvc');
require('./services/ServerSrvc');
require('./services/StatsSrvc');
require('./services/NotificationSrvc');
require('./services/CountrySrvc');
require('./services/FreshdeskSrvc');
require('./services/FAQSrvc');
require('./services/RatesSrvc');
require('./services/ConfigSrvc');
require('./services/PrizeSrvc');

// Load directives
require('./directives/navigation');
require('./directives/mccolorpicker');
require('./directives/unturnedcolorpicker');
require('./directives/fileinput');
